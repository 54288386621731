import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">



<path d="M1649 2156 l-65 -52 65 -31 c147 -71 283 -205 360 -353 21 -41 42
-85 46 -97 6 -22 4 -23 -54 -23 -42 0 -61 4 -61 13 0 26 -106 175 -169 237
-67 66 -190 150 -249 171 -30 10 -36 8 -97 -37 l-65 -48 68 -36 c86 -45 155
-95 213 -157 49 -51 109 -127 109 -137 0 -3 -29 -6 -64 -6 l-65 0 -58 61 c-89
94 -264 209 -297 196 -7 -3 -38 -24 -69 -47 l-57 -43 63 -36 c81 -47 222 -189
267 -268 l35 -62 411 -1 c226 0 413 2 415 5 12 12 -15 179 -42 260 -78 234
-261 426 -489 514 -44 17 -81 31 -82 30 -2 0 -33 -24 -69 -53z m147 -12 c206
-86 371 -256 454 -469 31 -78 51 -200 36 -220 -9 -12 -67 -15 -374 -16 -337
-1 -365 1 -388 18 -13 10 -24 22 -24 26 0 29 -145 185 -220 237 -58 40 -64 48
-49 66 27 33 47 35 91 13 71 -36 129 -79 210 -156 42 -40 80 -73 85 -74 4 0
19 -2 33 -4 44 -5 107 15 118 39 8 18 3 31 -25 73 -45 67 -148 163 -235 220
-67 42 -69 45 -53 63 9 10 27 21 39 23 20 3 117 -43 144 -70 7 -7 18 -13 23
-13 22 0 150 -141 202 -222 71 -109 106 -130 180 -105 33 11 48 36 40 67 -9
36 -62 140 -94 187 -33 47 -185 203 -199 203 -5 0 -15 6 -22 14 -8 7 -27 21
-43 29 -62 33 -62 34 -35 61 27 30 52 32 106 10z"/>
<path d="M870 1559 c-102 -77 -189 -145 -194 -150 -6 -5 126 -9 348 -9 l357 0
-33 46 c-65 91 -214 224 -280 249 -8 3 -96 -58 -198 -136z m257 56 c60 -40
163 -142 163 -162 0 -10 -47 -13 -250 -13 -236 0 -250 1 -239 18 17 24 241
190 259 191 8 1 38 -15 67 -34z"/>
<path d="M1150 1165 l0 -145 30 0 c26 0 30 3 30 29 0 33 9 61 19 61 3 0 17
-20 31 -45 21 -39 29 -45 57 -45 18 0 33 2 33 6 0 3 -19 33 -41 66 l-42 61 42
39 c22 21 41 41 41 43 0 3 -15 5 -32 5 -26 0 -41 -8 -70 -42 l-37 -43 -1 78 0
77 -30 0 -30 0 0 -145z"/>
<path d="M480 1160 l0 -140 30 0 c29 0 30 2 30 45 0 43 2 45 28 45 21 -1 33
-10 54 -45 24 -40 31 -45 63 -45 40 0 39 7 -8 65 l-25 29 29 27 c22 21 29 36
29 66 -1 71 -36 93 -149 93 l-81 0 0 -140z m151 80 c11 -6 19 -21 19 -35 0
-29 -26 -45 -74 -45 -36 0 -36 0 -36 45 0 45 0 45 36 45 19 0 44 -5 55 -10z"/>
<path d="M1360 1275 c0 -24 3 -25 57 -25 l57 0 -33 -34 c-41 -43 -38 -69 8
-76 41 -6 61 -31 42 -54 -17 -21 -50 -20 -80 0 -22 15 -24 15 -43 -4 l-21 -21
34 -20 c77 -47 173 -12 173 62 0 41 -27 77 -58 77 -17 0 -15 5 17 38 20 21 37
48 37 60 0 21 -4 22 -95 22 -95 0 -95 0 -95 -25z"/>
<path d="M1734 1160 l1 -140 65 0 c107 0 168 45 177 131 3 33 -1 52 -16 77
-34 53 -75 72 -157 72 l-71 0 1 -140z m163 90 c70 -42 63 -154 -11 -185 -20
-8 -54 -15 -76 -15 l-40 0 0 110 0 110 48 0 c29 0 59 -7 79 -20z"/>
<path d="M1590 1260 c0 -16 -4 -30 -10 -30 -5 0 -10 -11 -10 -25 0 -14 5 -25
10 -25 6 0 10 -26 10 -58 0 -77 19 -104 68 -100 31 3 37 7 40 29 2 21 -1 26
-20 25 -21 -1 -23 4 -26 55 -3 56 -3 56 23 52 22 -5 25 -2 25 24 0 27 -3 30
-25 25 -22 -4 -25 -1 -25 27 0 28 -3 31 -30 31 -27 0 -30 -3 -30 -30z"/>
<path d="M2230 1260 c0 -20 -5 -30 -15 -30 -8 0 -15 -7 -15 -15 0 -8 7 -15 15
-15 12 0 15 -15 15 -74 0 -86 14 -109 63 -104 43 4 46 26 5 30 l-33 3 -3 73
-3 72 36 0 c24 0 35 4 35 15 0 10 -11 15 -35 15 -32 0 -35 2 -35 30 0 20 -5
30 -15 30 -10 0 -15 -10 -15 -30z"/>
<path d="M772 1232 c-13 -4 -23 -10 -23 -14 1 -5 1 -15 1 -24 0 -13 7 -15 40
-9 27 5 46 3 55 -5 28 -23 17 -35 -29 -32 -38 3 -49 0 -70 -22 -31 -31 -32
-45 -5 -80 22 -28 64 -35 100 -16 13 7 19 7 19 0 0 -5 14 -10 30 -10 l30 0 0
75 c0 41 -5 85 -11 99 -18 38 -78 54 -137 38z m72 -118 c21 -8 21 -40 0 -48
-47 -18 -86 13 -49 40 21 16 27 17 49 8z"/>
<path d="M1004 1231 c-56 -24 -80 -101 -50 -160 27 -51 113 -68 157 -32 23 19
23 21 7 37 -14 14 -20 15 -30 6 -15 -15 -60 -16 -76 0 -18 18 -14 73 6 91 23
21 41 21 62 2 15 -14 19 -13 35 5 17 19 17 22 3 36 -18 19 -85 27 -114 15z"/>
<path d="M2038 1223 c-10 -2 -18 -11 -18 -18 0 -11 11 -13 50 -8 53 6 80 -6
80 -37 0 -14 -7 -16 -47 -13 -57 6 -103 -22 -103 -63 0 -55 88 -87 132 -47 17
15 18 15 18 0 0 -10 7 -17 15 -17 12 0 15 16 15 80 0 73 -3 83 -25 105 -24 24
-68 31 -117 18z m100 -103 c16 0 16 -45 -1 -59 -8 -6 -27 -14 -44 -17 -33 -7
-66 17 -61 44 4 22 48 44 73 37 11 -2 26 -5 33 -5z"/>
<path d="M2378 1223 c-10 -2 -18 -11 -18 -18 0 -11 11 -13 50 -8 53 6 80 -6
80 -37 0 -14 -7 -16 -47 -13 -57 6 -103 -22 -103 -63 0 -55 88 -87 132 -47 17
15 18 15 18 0 0 -10 7 -17 15 -17 12 0 15 16 15 84 0 79 -2 86 -26 105 -25 20
-72 26 -116 14z m100 -103 c16 0 16 -45 -1 -59 -8 -6 -27 -14 -43 -17 -34 -6
-71 24 -60 51 7 20 45 36 71 30 11 -3 26 -5 33 -5z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
